body {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Acids';
  src: local('Acids'), url(./fonts/Acids.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'MoreSugar-Extras';
  src: local('MoreSugar-Extras'), url(./fonts/more_sugar_font/MoreSugar-Extras.otf) format('opentype');
}
@font-face {
  font-family: 'MoreSugar-Regular';
  src: local('MoreSugar-Regular'), url(./fonts/more_sugar_font/MoreSugar-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'MoreSugar-Thin';
  src: local('MoreSugar-Thin'), url(./fonts/more_sugar_font/MoreSugar-Thin.otf) format('opentype');
}